export const refHandle = () => {

    const url = window.location.search;
    const regex = /\?ref=([^&/]+)/;

    const match = url.match(regex);

    if (match && match[1]) {
        sessionStorage.setItem('referrer', match[1]);
    }
    
}