import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isShown: false,
  twitter: null,
  ds: null,
  twLink: null,
  showSuccess: false
}

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    show: (state) => {
        state.isShown = true;
        document.querySelector("body").style.overflow = "hidden";
    },
    hide: (state) => {
        state.isShown = false;
        document.querySelector("body").style.overflow = "visible";
    },
    setTwitter: (state, action) => {
      state.twitter = action.payload;
    },
    setDs: (state, action) => {
      state.ds = action.payload
    },
    setTwLink: (state, action) => {
      state.twLink = action.payload
    },
    setShowSuccess: (state) => {
      state.showSuccess = true;
    }
  }
})

// Action creators are generated for each case reducer function
export const { show, hide, setTwitter, setDs, setTwLink, setShowSuccess} = modalSlice.actions

export default modalSlice.reducer