import { auth } from '../firebase';
import { TwitterAuthProvider, signInWithPopup, signInWithRedirect, getRedirectResult, onAuthStateChanged } from 'firebase/auth';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { setTwitter } from '../state/reducers/modalSlice';

import { sign } from '../firebase';

const TwitterAuth = ({ className, children }) => {
  const dispatch = useDispatch();

  console.log(auth)

  const signInWithTwitter = () => {
    const provider = new TwitterAuthProvider();
    signInWithPopup(auth, provider).then(res => {
      dispatch(setTwitter(res.user.reloadUserInfo.screenName))
    }).catch(error => console.log(error.message));
  };

  return (
    <div className={className} onClick={signInWithTwitter}>
      {children}
    </div>
  );
};

export default TwitterAuth;
