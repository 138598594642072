import bp from "../assets/backpack.png";
import bg from "../assets/bg-backpack.gif";

import { show } from "../state/reducers/modalSlice";
import { useDispatch } from "react-redux";

const FormWrapper = () => {
    const dispatch = useDispatch();
    return (
        <div className="form">
            <div className="form-wrapper" style={{ backgroundImage: `url(.${bg})` }} >
                <p className="form-wrapper__mint">Mint soon</p>
                <img id="backpack" src={"." + bp} alt="backpack" />
                <button onClick={() => dispatch(show())} className="form-wrapper__btn">Join waitlist</button>
            </div>
        </div>
    )
}

export default FormWrapper;