import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

import { Provider } from "react-redux";
import { store } from "./state";

import WalletProvider from './context/WalletProvider';

import { refHandle } from './functions/refHandle';

sessionStorage.removeItem('referrer');
refHandle();


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <WalletProvider>
      <App />
    </WalletProvider>
  </Provider>
);
