import { Component, useEffect, useRef } from "react";

import { ReactComponent as LogoIcon } from "../assets/logo.svg";
import { ReactComponent as DropDownIcon } from "../assets/chevron-down.svg";
import burger from "../assets/burger.png";
import avatar from "../assets/avatar.png";

import { screenWidthLessThan } from "../functions/helpers/screenWidthLessThan";

import { useWallet } from "@solana/wallet-adapter-react";

import { shorted } from "../functions/helpers/shorted";
import { walletDisconected } from "../state/reducers/walletSlice";
import { useDispatch } from "react-redux";


const Navbar = () => {
    const menuContent = useRef(null);
    const openBurgerBtn = useRef(null);
    const closeBurgerBtn = useRef(null);

    const { publicKey, connected, disconnect } = useWallet();

    const dispatch = useDispatch();

    useEffect(() => {
        if (!connected) {
            dispatch(walletDisconected());
        }
    }, [connected])

    useEffect(() => {
        const body = document.querySelector("body")

        if (screenWidthLessThan(787)) {
            menuContent.current.classList.add("navbar__menu_mob");

            openBurgerBtn.current.addEventListener("click", () => {
                menuContent.current.style.transform = "translateX(0%)";
                body.style.overflow = "hidden";
            })
            closeBurgerBtn.current.addEventListener("click", () => {
                menuContent.current.style.transform = "translateX(100%)";
                body.style.overflow = "";
            })
            menuContent.current.addEventListener("click", e => {
                if (e.target.classList.contains("navbar__link")) {
                    menuContent.current.style.transform = "translateX(100%)";
                    body.style.overflow = "";
                }
            })
        }
    }, [])

    return (
        <div id="navbar-container" className="container">
            <nav className="navbar">
                <div className="navbar__logo-wrapper">
                    <div className="navbar__log-svg">
                        <LogoIcon />
                    </div>
                    <p className="navbar__logo">Fungypack</p>
                </div>
                <img ref={openBurgerBtn} className="navbar__burger" src={burger} alt="burger" />
                <div ref={menuContent} className="navbar__content">
                    <div className="navbar__links">
                        <p ref={closeBurgerBtn} id="menu-close" style={{ color: "white" }}>&#x2716;&#xFE0E;</p>
                        <a href="https://fungypack.com/#about" className="navbar__link">About</a>
                        <a href="https://fungypack.com/#roadmap" className="navbar__link">Roadmap</a>
                        <a href="https://fungypack.com/#features" className="navbar__link">Features</a>
                        <a href="https://fungypack.com/#qa" className="navbar__link">FAQ</a>
                    </div>
                    <div className="wallet-panel" data-dd={connected}>
                        {
                            connected ?
                                <>
                                    <div className="wallet-panel__main">
                                        <img src={avatar} className="wallet-panel__img"></img>
                                        <div className="wallet-panel__address">{shorted(publicKey.toString())}</div>
                                        <DropDownIcon />
                                    </div>
                                    <div className="wallet-panel__dd">
                                        <p onClick={() => disconnect()} className="wallet-panel__disc">Disconect</p>
                                    </div>
                                </>
                                :
                                <p className="wallet-panel__placeholder">Not connected</p>
                        }
                    </div>

                </div>
            </nav>
        </div>

    )
}

export default Navbar;