
import AvaliableSoon from "./AvaliableSoon";

const Stake = () => {
    return (
        <div className="stake">
            <div className="container">
                <p className="stake__title">Stake Pack</p>
                <p className="stake__sub">Partners integration staking</p>
                <AvaliableSoon/>
            </div>
        </div>
    )
}

export default Stake;