import { ReactComponent as WaitIcon } from "../assets/wait.svg";


const AvaliableSoon = () => {
    return (
        <div className="avaliablesoon">
            <WaitIcon/>
            <p>Available soon</p>
        </div>
    )
}

export default AvaliableSoon;