
import AvaliableSoon from "./AvaliableSoon";

import { useEffect, useRef, useState } from "react";

import { useSelector } from "react-redux";

import { ReactComponent as CopyIcon } from "../assets/copy.svg";

import { screenWidthLessThan } from "../functions/helpers/screenWidthLessThan";

import { useDispatch } from "react-redux";

import { show } from "../state/reducers/modalSlice";


const Referal = () => {

    const copyIcon = useRef(null);
    const parentrRef = useRef(null);
    const [width, setWidth] = useState(0);

    const dispatch = useDispatch();

    const { walletAddress } = useSelector(state => state.wallet);
    const { showSuccess } = useSelector(state => state.modal);

    const referralLink = `${window.location.hostname}/?ref=${walletAddress}`;

    useEffect(() => {
        setWidth(parentrRef.current.offsetWidth)
        console.log(width)
    }, [])

    return (
        <div onClick={() => showSuccess ? "" : dispatch(show())} ref={parentrRef} className="pannel__ref-wrapper">
            {
                showSuccess ?
                    <>
                        {
                            screenWidthLessThan(766) ?
                                <p >{`${referralLink.slice(0, (width - 22) / 10)}...`}</p>
                                :
                                <p >{`${referralLink.slice(0, (width - 22) / 16)}...`}</p>
                        }
                        <button onClick={() => {
                            navigator.clipboard.writeText(referralLink)
                        }} value={referralLink} className="pannel__ref-copy">
                            Copy link
                            <CopyIcon ref={copyIcon} />
                        </button>
                    </>
                    :
                    <p >Apply for waitlist to get link</p>
            }
        </div>
    )
}

export default Referal;