import { ReactComponent as CloseIcon } from "../assets/close.svg";

import { hide, setTwLink, setShowSuccess } from "../state/reducers/modalSlice";
import { useDispatch } from "react-redux";

import { ReactComponent as WalletIcon } from "../assets/wallet.svg";
import { ReactComponent as XIcon } from "../assets/twitter.svg";
import { ReactComponent as DsIcon } from "../assets/ds.svg";
import { ReactComponent as DelIcon } from "../assets/delete.svg";
import { ReactComponent as DoneIcon } from "../assets/done.svg";
import { ReactComponent as OkIcon } from "../assets/ok.svg";
import { ReactComponent as ShareIcon } from "../assets/share.svg";

import Referal from "./Referal";

import TwitterAuth from "./TwitterAuth";
import DiscordAuth from "./DiscordAuth";

import { useSelector } from "react-redux";

import { useState, useRef, useEffect } from "react";

import { setRefferer, removeRefferer } from "../state/reducers/refSlice";

import { fetchScript } from "../functions/db";

import { screenWidthLessThan } from "../functions/helpers/screenWidthLessThan";

import { setTwitter, setDs } from "../state/reducers/modalSlice";

const Popup = () => {
    const [link, setLink] = useState("");
    const [linkError, setLinkError] = useState(null);
    const [isOpened, setOpened] = useState(false);
    const [referrerAddress, setReferrerAddress] = useState("");
    const [disabled, setDisable] = useState(true);
    const dispatch = useDispatch();
    const linkRef = useRef(null);
    const okRef = useRef(null);

    const twMobInputRef = useRef(null);
    const [twMobValue, setTwMobValue] = useState("");

    const dsMobInputRef = useRef(null);
    const [dsMobValue, setDsMobValue] = useState("");

    const { twitter, ds, twLink, showSuccess } = useSelector(state => state.modal);
    const { walletAddress } = useSelector(state => state.wallet);
    const { referrer } = useSelector(state => state.ref);

    useEffect(() => {
        if (screenWidthLessThan(767)) {
            if (twMobInputRef) {
                twMobInputRef.current.addEventListener('blur', function (e) {
                    dispatch(setTwitter(e.target.value));
                });
            }
            if (dsMobInputRef) {
                dsMobInputRef.current.addEventListener('blur', function (e) {
                    dispatch(setDs(e.target.value));
    
                });
            }
        }

    }, [twMobInputRef, dsMobInputRef])

    useEffect(() => {
        const arr = [walletAddress, twitter, ds, twLink];
        const isValid = arr.every(elem => elem && true);
        setDisable(!isValid);
    }, [walletAddress, twitter, twLink, ds])

    const onLinkInput = (value) => {
        const tweetUrlPattern = new RegExp(`^https://x\\.com/${twitter}/status/\\d+$`); //
        if (tweetUrlPattern.test(value)) {
            dispatch(setTwLink(value))
            setLinkError(null);
        } else {
            setLinkError("Link not valid")
        }
        return;
    }

    const onApply = async (action) => {
        const data = {
            action: action,
            walletAddress,
            twitter,
            discord: ds,
            referals: 0,
            postLink: twLink,
            referrerAddress: referrer
        }
        const res = await fetchScript(data);
        if (res.result = "new user added") {
            dispatch(setShowSuccess());
        }
    }

    return (
        <div className="popup">
            <div className="modal">
                {
                    !showSuccess ?
                        <>
                            <div className="modal__top">
                                <p className="modal__title">Apply for Waitlist</p>
                                <button onClick={() => dispatch(hide())} className="modal__close">
                                    <CloseIcon />
                                </button>
                            </div>

                            <div className="modal__form form">
                                <div className="form__control">
                                    <p className="form__step">Step 1</p>
                                    <div className="form__input">
                                        {
                                            walletAddress ?
                                                <>
                                                    <p className="form__content">
                                                        <DoneIcon className="form__completed" />
                                                        {`${walletAddress.slice(0, 8)}...${walletAddress.slice(-8)}`}
                                                    </p>
                                                    <DelIcon />
                                                </>
                                                :
                                                <>
                                                    <p className="form__placeholder"> Connect your wallet</p>
                                                    <WalletIcon />
                                                </>
                                        }


                                    </div>
                                </div>
                                <div className="form__control">
                                    <p className="form__step">Step 2</p>
                                    {
                                        screenWidthLessThan(767) ?
                                            <div className="form__input">
                                                <>
                                                    <div className={twitter ? "form__content" : "mobInput"}>
                                                        {twitter ? <DoneIcon className="form__completed" /> : ""}
                                                        <input ref={twMobInputRef} value={twMobValue} onChange={(e) => {
                                                            setTwMobValue(e.target.value);
                                                            console.log("change")
                                                        }} className={twitter ? "form__content" : "form__placeholder"} type="text" placeholder={twitter ? twitter : "Your x handle"} />
                                                        <XIcon />
                                                    </div>
                                                </>
                                            </div>
                                            :
                                            twitter ?
                                                <div className="form__input">
                                                    <p className="form__content">
                                                        <DoneIcon className="form__completed" />
                                                        {`@${twitter}`}
                                                    </p>
                                                    <XIcon />
                                                </div>
                                                :
                                                <TwitterAuth className={"form__input"} text={""}>
                                                    <p className="form__placeholder">Connect X</p>
                                                    <XIcon />

                                                </TwitterAuth>
                                    }

                                </div>
                                <div className="form__control">
                                    <p className="form__step">Step 3</p>
                                    {
                                        screenWidthLessThan(767) ?
                                            <div className="form__input">
                                                <div className={ds ? "form__content" : "mobInput"}>
                                                    {ds ? <DoneIcon className="form__completed" /> : ""}
                                                    <input ref={dsMobInputRef} value={dsMobValue} onChange={(e) => {
                                                        setDsMobValue(e.target.value);
                                                        console.log("change")
                                                    }} className={ds ? "form__content" : "form__placeholder"} type="text" placeholder={ds ? ds : "Your Discord handle"} />
                                                    <DsIcon />
                                                </div>
                                            </div>
                                            :
                                            ds ?
                                                <div className="form__input">
                                                    <p className="form__content">
                                                        <DoneIcon className="form__completed" />
                                                        {`@${ds}`}
                                                    </p>
                                                    <DsIcon />
                                                </div>

                                                :
                                                <DiscordAuth className={"form__input"}>
                                                    <p className="form__placeholder">Connect Discord</p>
                                                    <DsIcon />
                                                </DiscordAuth>
                                    }
                                </div>
                                <div className="form__control">
                                    <p className="form__step">Step 4 {<span>{linkError}</span> || ""}</p>
                                    {
                                        screenWidthLessThan(767) ?
                                            twLink ?
                                                <div className="form__input">
                                                    <p className="form__content">
                                                        <DoneIcon className="form__completed" />
                                                        Tank You!
                                                    </p>
                                                    <XIcon />
                                                </div>
                                                :
                                                <div ref={linkRef} className="form__input">
                                                    <input style={{ pointerEvents: "auto" }} value={link} onChange={(e) => {
                                                        setLink(e.target.value)
                                                        onLinkInput(e.target.value);
                                                    }} placeholder="Share post, enter link" className="form__placeholder" />
                                                    <ShareIcon onClick={() => {
                                                        if (!isOpened) {
                                                            window.open("https://x.com/intent/post?text=Hello+world", "_blank")
                                                            setOpened(true);
                                                        }
                                                    }} />
                                                </div>
                                            :
                                            twLink ?
                                                <div className="form__input">
                                                    <p className="form__content">
                                                        <DoneIcon className="form__completed" />
                                                        Tank You!
                                                    </p>
                                                    <XIcon />
                                                </div>
                                                :
                                                <div ref={linkRef} onClick={() => {
                                                    if (!isOpened) {
                                                        window.open("https://x.com/intent/post?text=Hello+world", "_blank")
                                                        setOpened(true);
                                                    }
                                                }} className="form__input">
                                                    <input style={{ pointerEvents: "auto" }} value={link} onChange={(e) => {
                                                        setLink(e.target.value)
                                                        onLinkInput(e.target.value);
                                                    }} placeholder="Share post, and enter the link" className="form__placeholder" />
                                                    <XIcon />
                                                </div>
                                    }
                                </div>
                                <div className="form__control">
                                    <p className="form__step">Referrer address</p>

                                    <div className="form__input">
                                        {
                                            referrer ?
                                                <>
                                                    <p className="form__content"> {`${referrer.slice(0, 8)}...${referrer.slice(-8)}`}</p>

                                                    <DelIcon onClick={() => {
                                                        dispatch(removeRefferer())
                                                        setReferrerAddress("");
                                                    }} />
                                                </>
                                                :
                                                <>
                                                    <input value={referrerAddress} onChange={(e) => {
                                                        setReferrerAddress(e.target.value)
                                                        // dispatch(setRefferer(e.target.value))
                                                        okRef.current.style.display = "block";
                                                    }} className="form__placeholder" placeholder="Enter referrer address" />
                                                    <OkIcon onClick={() => {
                                                        dispatch(setRefferer(referrerAddress))
                                                    }} style={{ display: "none" }} ref={okRef} />

                                                </>
                                        }
                                    </div>
                                    <p className="form__sub">Optional</p>
                                </div>
                            </div>

                            <button onClick={() => onApply("save_data")}
                                className="modal__button"
                                disabled={disabled ? true : false}
                            >
                                Apply
                            </button>
                        </>

                        :

                        <>
                            <div className="modal_success">
                                <div className="modal__top">
                                    <p className="modal__title">Well Done</p>
                                    <button onClick={() => dispatch(hide())} className="modal__close">
                                        <CloseIcon />
                                    </button>
                                </div>
                                <div className="modal__message">
                                    You have successfully submitted for the Fungypack whitelist. Good luck, and stay tuned for updates!
                                </div>

                                <div>
                                    <div className="modal__text">Share your refferal link to get more bonuses:</div>
                                    <Referal />

                                </div>

                                <button onClick={() => dispatch(hide())} className="modal__button" disabled={false}>
                                    Got it
                                </button>
                            </div>
                        </>
                }
            </div>
        </div>
    )
}

export default Popup;