import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  referrer: null,
  referals: 0
}

export const refSlice = createSlice({
  name: 'ref',
  initialState,
  reducers: {
    checkRefferer: (state) => {
      state.referrer = sessionStorage["referrer"];
    },
    setRefferer: (state, action) => {
      state.referrer = action.payload
    },
    removeRefferer: (state) => {
      state.referrer = null
    },
    setReferals: (state, action) => {
      const referals = action.payload ? action.payload : 0;
      state.referals = referals;
    }
  },
})

// Action creators are generated for each case reducer function
export const { checkRefferer, setRefferer, removeRefferer, setReferals } = refSlice.actions

export default refSlice.reducer