import FormWrapper from "./FormWrapper";
import Pannel from "./Pannel";
import Buffs from "./Buffs";

import Popup from "./Popup";

import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { checkRefferer } from "../state/reducers/refSlice";
import { useEffect } from "react";

const Dashboard = () => {
    const dispatch = useDispatch();

    const { isShown } = useSelector(state => state.modal);

    useEffect(() => {
        dispatch(checkRefferer())
    },[])

    return (
        <div id="dashboard-container" className="container" >
            <div className="dashboard">
                <Pannel />
                <FormWrapper />
                <Buffs />
            </div>
            {
                isShown ?
                    <Popup />
                    :
                    ""
            }
        </div>
    )
}

export default Dashboard;