export const fetchScript = async (data) => {
    const url = process.env.REACT_APP_GOOGLE_SHEET;

    const response = await fetch(url, {
        method: 'POST',
        body: new URLSearchParams({
          action: data.action,
          wallet_address: data.walletAddress,
          twitter: data.twitter,
          discord: data.discord,
          referals: data.referals,
          post_link: data.postLink,
          referrer_address: data.referrerAddress,
        }),
      });
      return response.json();
}