import { configureStore } from '@reduxjs/toolkit';
import walletReducer from "./reducers/walletSlice";
import modalSlice from './reducers/modalSlice';
import refSlice from "./reducers/refSlice";

export const store = configureStore({
  reducer: {
    wallet: walletReducer,
    modal: modalSlice,
    ref: refSlice
  },
})
