import React, { useEffect, useState } from 'react';
import axios from 'axios';


import { useDispatch } from 'react-redux';
import { setDs } from '../state/reducers/modalSlice';

const AUTH_URL = `https://discord.com/api/oauth2/authorize?client_id=${process.env.REACT_APP_DISCORD_CLIENT_ID}&redirect_uri=${encodeURIComponent(process.env.REACT_APP_DISCORD_REDIRECT_URI)}&response_type=code&scope=identify`;

function DiscordAuth({children, className}) {

  const dispatch = useDispatch();

  useEffect(() => {
    const handleMessage = async (event) => {
      // if (event.origin !== window.location.origin) return;

      const { code } = event.data;
      if (code) {
       
        try {
          // Exchange code for access token
          const tokenResponse = await axios.post(
            'https://discord.com/api/oauth2/token',
            new URLSearchParams({
              client_id: process.env.REACT_APP_DISCORD_CLIENT_ID,
              client_secret: process.env.REACT_APP_DISCORD_CLIENT_SECRET,
              grant_type: 'authorization_code',
              code: code,
              redirect_uri: process.env.REACT_APP_DISCORD_REDIRECT_URI,
            }),
            {
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
              },
            }
          );

          const { access_token } = tokenResponse.data;

          // Use the access token to fetch user data
          const userResponse = await axios.get(
            'https://discord.com/api/users/@me',
            {
              headers: {
                Authorization: `Bearer ${access_token}`,
              },
            }
          );

          dispatch(setDs(userResponse.data.username))

        } catch (error) {
          console.error('Error exchanging code for token:', error);
        }
      }
    };

    window.addEventListener('message', handleMessage);
    return () => window.removeEventListener('message', handleMessage);
  }, []);

  const openPopup = () => {
    const width = 500;
    const height = 600;
    const left = window.innerWidth / 2 - width / 2;
    const top = window.innerHeight / 2 - height / 2;
    window.open(
      AUTH_URL,
      'Discord Auth',
      `width=${width},height=${height},top=${top},left=${left}`
    );
  };

  return (
    <div>
      <button className={className} style={{ color: 'white' }} onClick={openPopup}>
        {children}
      </button>
    </div>
  );
}

export default DiscordAuth;
