import "./sass/index.scss";

import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Dashboard from "./components/Dashboard";
import Nfts from "./components/Nfts";
import Stake from "./components/Stake";

import WalletConnection from "./components/WalletConnection";

import { useSelector } from "react-redux";

import DiscordAuth from "./components/DiscordAuth";

import TwitterAuth from "./components/TwitterAuth";

import { BrowserRouter as Router } from 'react-router-dom';

import { auth } from "./firebase";


function App() {

  const { walletAddress } = useSelector((state) => state.wallet);

  return (
    <Router>
      <div className="App">
        <Navbar />
        {
          !walletAddress ?
            <WalletConnection />
            :
            <>
              <Dashboard />
              <Nfts />
              <Stake />
            </>
        }

        <Footer />
      </div>
    </Router>
  );
}

export default App;


