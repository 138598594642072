import boxes from "../assets/boxes.png";

import AvaliableSoon from "./AvaliableSoon";

const Nfts = () => {
    return (
        <div className="nfts">
            <div className="container-big">
                <p className="nfts__title">Your NFTs</p>
                <AvaliableSoon/>
                <img src={boxes} alt="boxes" />
            </div>
        </div>
    )
}

export default Nfts;