import BuffIcon1 from  "../assets/buffs/1.png";
import BuffIcon2 from  "../assets/buffs/2.png";
import BuffIcon3 from  "../assets/buffs/3.png";


const Buffs = () => {
    return (
        <div className="buffs" >
            <p className="buffs__title">Patches & Buffs</p>
            <div className="buffs__wrapper">
                <div className="buff">
                <img src={BuffIcon2} alt="buff" />
                    <p className="buff__hidden">Unique rewards accumulation for Fungypack & Phantom wallet users </p>
                </div>
                <div className="buff">
                    <img src={BuffIcon1} alt="buff" />
                    <p className="buff__hidden">Sale packed NFTs without loosing profit while it yours. </p>
                </div>
                <div className="buff">
                <img src={BuffIcon3} alt="buff" />
                    <p className="buff__hidden">Get 50% more rewards for every packed Genopets NFT </p>
                </div>
            </div>
        </div>
    )
}

export default Buffs;