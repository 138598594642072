import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  walletAddress: null,
  sol: null,
  nft: null,
  error: null
}

export const walletSlice = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    walletDisconected: (state) => {
        state.walletAddress = null;
        state.error = null;
    },
    walletLoaded: (state, action) => {
        state.walletAddress = action.payload;
        state.error = null;
    },
    walletError: (state, action) => {
        state.error = action.payload;
    },
    setSols: (state, action) => {
        state.sol = action.payload;
    },
    setNft: (state, action) => {
        state.nft = action.payload;
    },
  },
})

// Action creators are generated for each case reducer function
export const { walletDisconected, walletLoaded, walletError, setSols, setNft } = walletSlice.actions

export default walletSlice.reducer