import React, { useEffect } from 'react';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { useWallet } from '@solana/wallet-adapter-react';
import { useDispatch } from 'react-redux';
import { walletLoaded, walletDisconected } from '../state/reducers/walletSlice';

const WalletConnection = () => {
    const { publicKey, connect, connected } = useWallet();
    const dispatch = useDispatch();

    useEffect(() => {
        if (connected) {
            dispatch(walletLoaded(publicKey.toString()));
        } else {
            dispatch(walletDisconected());
        }
    }, [connected, dispatch, publicKey]);

    const handleConnect = async () => {
        try {
            await connect();
        } catch (error) {
            console.error('Failed to connect wallet:', error);
        }
    };

    return (
        <div className='wallet-connection'>
            <p className="wallet-connection__title">Welcome, please connect your Phantom wallet.</p>
            <WalletMultiButton onClick={handleConnect} />
            <p className="wallet-connection__info" style={{textAlign: "center"}}>If you don't have Phantom installed, you will be prompted to download it.</p>
        </div>
    );
};

export default WalletConnection;
