import { ReactComponent as StripesIcon } from "../assets/stripes.svg";
import { ReactComponent as CopyIcon } from "../assets/copy.svg";


import { shorted } from "../functions/helpers/shorted";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import Referal from "./Referal";
import { useEffect, useState } from "react";

import { fetchScript } from "../functions/db";

import { setReferals } from "../state/reducers/refSlice";
import { setShowSuccess } from "../state/reducers/modalSlice";
import { screenWidthLessThan } from "../functions/helpers/screenWidthLessThan";
import { setNft, setSols } from "../state/reducers/walletSlice";

// const url = "http://localhost:3001/";

const Pannel = () => {

    const { walletAddress, sol, nft } = useSelector(state => state.wallet);
    const { referals } = useSelector(state => state.ref);
    const [usd, setUsd] = useState(0)
    const dispatch = useDispatch();

    useEffect(() => {
        const data = {
            action: "get_data",
            walletAddress
        }
        const toFetch = async () => {
            const res = await fetchScript(data);
            dispatch(setReferals(res.referals));
            if (res.user) {
                dispatch(setShowSuccess());
            }
        }
        toFetch();
    }, []);

    useEffect(() => {

        const getBalance = async () => {
            const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/sol.php`, {
                method: "POST",
                headers: {
                    "Content-type": "application/json"
                },
                body: JSON.stringify({
                    wallet: walletAddress
                })
            })
            const result = await res.json();
            if (result.value || result.value === 0) {
                dispatch(setSols(result.value));
            }
        }
        const fetchNfts = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/token.php`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        wallet: walletAddress
                    }),
                });
                const result = await response.json();
                if (result.count || result.count === 0) {
                    dispatch(setNft(result.count));
                }
            } catch (e) {
                console.log(e.message)
            }
        };

        if (walletAddress) {
            getBalance();
            fetchNfts();
        }
    }, [walletAddress]);

    useEffect(() => {
        const convert = async () => {
            const resp = await fetch("https://api.coingecko.com/api/v3/simple/price?ids=solana&vs_currencies=usd");
            const { solana: { usd } } = await resp.json();
            setUsd(sol * usd);
        }
        if (sol || sol === 0) {
            convert();
        }
    }, [sol])


    return (
        <div className="pannel" >
            <div className="pannel__basic">
                <div className="pannel__address">
                    <StripesIcon />
                    <p className="pannel__address-content">{walletAddress ? shorted(walletAddress) : ""}</p>
                </div>
                <p className="pannel__sol">{`${sol || sol === 0 ?
                    sol.toFixed(4)
                    :
                    "__.__"} SOL`}</p>
                <p className="pannel__fiat">{usd || usd === 0 ? `${usd.toFixed(2)} USD` : ""}</p>
            </div>
            <div className="pannel__info box">
                <div className="pannel__info-part">
                    <div className="pannel__info-row">
                        <p className="pannel__info-label">Address</p>
                        <div className="pannel__info-address">
                            <p className="pannel__info-content">{walletAddress ?
                                screenWidthLessThan(767) ?
                                    `${walletAddress.slice(0, 10)}...${walletAddress.slice(-10)}`
                                    :
                                    shorted(walletAddress)
                                : ""}</p>
                            <CopyIcon onClick={() => {
                                navigator.clipboard.writeText(walletAddress)
                            }} />
                        </div>
                    </div>
                    <div className="pannel__info-row">
                        <p className="pannel__info-label">NFTs</p>
                        <p className="pannel__info-content">{nft || nft === 0 ? nft : ""}</p>
                    </div>
                    <div className="pannel__info-row">
                        <p className="pannel__info-label">Your referrals</p>
                        <p className="pannel__info-content">{referals}</p>
                    </div>
                </div>
                <div className="pannel__info-part">
                    <div className="pannel__info-row">
                        <p className="pannel__info-label">Earned Points</p>
                        <p className="pannel__info-content">0</p>
                    </div>
                    <div className="pannel__info-row">
                        <p className="pannel__info-label">NFT in Backpack</p>
                        <p className="pannel__info-content">0</p>
                    </div>
                    <div className="pannel__info-row">
                        <p className="pannel__info-label">Staked NFTs</p>
                        <p className="pannel__info-content">0</p>
                    </div>
                </div>
            </div>
            <div className="pannel__ref">
                <p className="pannel__ref-title">Referral link</p>
                <Referal />
            </div>
        </div>
    )
}

export default Pannel;